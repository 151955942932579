@use "../variables";

a {
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: 744px) {
  .landing-card {
    border: 1px solid rgb(218, 220, 224);
    border-radius: 12px;
    padding: 24px !important;
    margin-bottom: 100px;
  }
}

.landing-card {
  margin-bottom: 25px;
  margin-top: 25px;
  padding: 0;
  input {
    max-width: 315px;
  }
  a {
    text-decoration: none;
  }
  .card-bottom {
    ursor: pointer;
    background-color: $gray-lighter;
    padding: 16px 0px;
    h4 {
      margin: 0px;
    }
  }
}

.zwi-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.zwi-toolbar {
  background: white;
  border-bottom: 1px solid variables.$divider;
}

// UL

.zwi-bullet-list {
  margin-left: 16px;
  list-style: initial;
}

// Wrappers
//.zwi-page-wrapper {
//  height: 100%;
//  overflow-x: auto;
//  overflow-y: hidden;
//}

.zwi-page-wrapper-mobile {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}


// Embed
.zwi-embed-cover-art {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 12px;
}

// Text
.zwi-text-overflow {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.zwi-text-overflow::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

// Chips
.zwi-chip-nowrap {
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.zwi-chip-nowrap .mdc-evolution-chip-set__chips {
  flex-flow: nowrap;
}

.zwi-mobile-chip-set:after {
  content: '';
  display: block;
  width: 24px;
}

.zwi-mobile-chip-set {
  & mat-chip-option:first-of-type {
    margin-left: 32px;
  }

  & mat-chip-option:last-of-type {
    position: relative;
  }

  & mat-chip-option:last-of-type::after {
    content: '';
    display: block;
    width: 26px; /* Adjust the value as needed */
    background-color: white;
    position: absolute;
    top: 0;
    right: -26px; /* Adjust the value to match the width */
    height: 100%;
  }
}
