// Search
//------------------------

// .search__input {
//     width: 400px !important;
//     border-radius: 3px 0 0 3px;
// }

// .search__button {
//     color: $offset;
//     vertical-align: middle;
//     border: 1px solid transparent;
//     height: 44px;
//     width: 70px;
//     background-color: #1b74cc;
//     border-radius: 0 3px 3px 0;
// }

.search-button {
    color: $gray-font;
    margin-right: 16px;
    margin-left: 16px;
    cursor: default;
}

.search-button--active {
    color: $blue-primary;
    cursor: pointer;
}

.search__headline {
    font-size: $placeholder-font-size;
    color: $gray-dark;
    margin-top: $medium-margin;
}

.searchbar {
    form {
        width: 100%;
        margin-bottom: 0px;
    }
    input {
        line-height: 3.2 !important;
        margin: 0;
        border: none;
    }
    input:focus {
        outline: none;
    }
}