.zwi-side-menu__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 20px;

}

.zwi-side-menu__icon {
  margin-right: 16px;
}

.zwi-side-menu__main {
  grid-area: main;
}
