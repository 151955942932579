// Placeholder
//------------------------

.placeholder--title {
    margin-bottom: 16px;
}

.placeholder {
    .material-icons {
        font-size: 80px;
    }
}