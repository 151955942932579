$player-button-size: 56px;

.player__container {
    position: relative;
    z-index: 10;
    background-color: $offset;
    height: 75px;
    min-height: 75px;
    width: 100%;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

//.player__button, .player__button__icon {
//  font-size: $player-button-size !important;
//  height: $player-button-size !important;
//  width: $player-button-size !important;
//  line-height: $player-button-size !important;
//  display: block !important;
//}

//.player--inactive .player__button {
//    color: $inactive-font;
//}

.player__middle {
    width: 100%;
    height: 75px;
}

.player--inactive .player__secondary-icon {
    color: $inactive-font;
}

.player {
    min-height: 0;
}

.player__current-time {
    position: absolute;
    left: 10px;
    top: 5px;
}

.player__end-time {
    position: absolute;
    right: 10px;
    top: 5px;
}

.player__information {
    position: absolute;
    left: 40px;
    top: 20px;
}

.player__information__caption {
  line-height: 1 !important;
}

.player__extras {
    position: absolute;
    right: 36px;
    top: 24px;
}

.player__progress-slider-container {
  top: -24px;
  position: absolute;
  width: 100%;
  .mat-mdc-slider {
    padding: 8px 0;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  .mat-slider-horizontal .mat-slider-wrapper {
    left: 0px;
    right: 0px;

  }
}
