// Sermons
//------------------------

.studio-side {
    width: 229px;
}

// Side Menu
//------------------------

.side-menu--studio {
    width: 229px;
    margin-left: 10px;
}


