@use 'sass:map';
@use '@angular/material' as mat;

@mixin tile-theme($theme) {
  .zwi-tile__subtitle {
    color: mat.get-theme-color($theme, foreground, secondary-text);
  }
}

@mixin tile-typography($config) {
  .zwi-tile__title {
    @include mat-typography-level-to-styles($config, title);
  }

  .zwi-tile__subtitle {
    @include mat-typography-level-to-styles($config, caption);
  }

  .zwi-tile__line {
    @include mat-typography-level-to-styles($config, caption);
  }
}



