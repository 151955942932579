// Form
//------------------------

// New

.zwi-form-group {
    margin: $medium-margin 0;
}

// Previous

.avatar__frame {
    background-color: $offset;
    @include box-shadow(0px 1px 1px rgba(0,0,0,.2));
    padding: 12px;
    display: inline-block;
}

.avatar__placeholder {
    width: 195px;
    height: 195px;
    background-color: $gray-light;
}

.avatar__placeholder-message {
    text-align: center;
    width: 195px;
    height: 195px;
}

.control-label {
	color: rgba(0,0,0,.26);
    font-weight: 700;
    padding-left: 4px;
    margin: 0;
}

.form__label {
    color: rgba(0,0,0,.38);
    font-weight: 700;
}

.form-row {
	margin-bottom: 25px;
}

.form-scrollable-doc {
	height: 300px;
    background-color: #fff;
    border: 1px solid #6e6e6e;
    overflow-y: auto;
    padding: 10px 20px;
}

.form-control {
    padding: 14px!important;
    font-size: 1.6rem;
    height: 44px;
}

.form-lg .form-control {
    font-size: 1.8rem;
    height: 50px;
}

.horizontal-form__help-text {
	margin: 13px 0 0 15px;
}

.horizontal-form__label {
    margin-top: 16px;
}

textarea.form-control {
    height: auto !important;
}

.signup-username-input {
	display: inline-block;
	width: 258px;
}

// Error
//------------------------

.errors-spacer {
    min-height: 24px;
    min-width: 1px;
}

.textfield__error {
    color: $brand-danger;
    font-size: 12px;
    margin-top: 3px;
}

.errorlist {
    list-style: none;
    padding: 0;
}

.textfield__label {
    color: $input-label;
    font-weight: $bold;
}

// Input
//------------------------

.input--medium {
    width: 400px;
}

.input--full-width {
    width: 100%;
}

// Layout
.zwi-form-group {
    .zwi-col-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
    }
}

// Buttons
.zwi-form-buttons  {
    display: flex;
    justify-content: flex-end;
}
