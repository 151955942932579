////////////////
// POSSIBLY OLD
////////////////

.list-card {
  width: 100%;
  margin: 20px 0 40px 0;
  padding-bottom: 8px !important;
}

md-card > md-list {
  padding-bottom: 8px;
}

////////////////
// POSSIBLY OLD
////////////////

//.landing-card {
//  border: 1px solid rgb(218, 220, 224);
//  padding: 0;
//  margin-top: 25px;
//  margin-bottom: 100px;
//  input {
//    max-width: 315px;
//  }
//  .card-bottom {
//    background-color: $gray-lighter;
//    padding: 16px 0px;
//    h4 {
//      margin: 0px;
//    }
//  }
//  //   h4, .h4 {
//  //     color: @gray-darker;
//  //     font-size: 2.0rem;
//  //     margin: 6px 0;
//  //   }
//  //   h4.title {
//  //     font-weight: @bold;
//  //     margin-bottom: 2px;
//  //     margin-top: 20px;
//  //   }
//  //   h6 {
//  //     margin: 0;
//  //   }
//  //   p, .p {
//  //     color: @gray-darker;
//  //   }
//  //   a {
//  //     color: @gray-darker;
//  //     font-weight: @bold;
//  //   }
//}

.login-card {
  position: relative;
  width: 450px;
  margin: 100px auto;
  padding: 0;
  // .floating-card();
  // h1 {
  //   margin-top: 0;
  //   margin-bottom: 25px;
  // }
  // h6, .h6 {
  //   color: @gray-darker;
  //   margin-bottom: 10px;
  // }
}

.signup-card {
  width: 470px;
  margin: 25px auto;
  padding: 25px;
  // .floating-card();
  // .two-by-two {
  //   .make-row();
  //   .left-side {
  //     .make-md-column(6, 16);
  //     padding-left: 15px;
  //   }
  //   .right-side {
  //     .make-md-column(6, 16);
  //     padding-right: 15px;
  //   }
  // }
  // .gender-radios {
  //   margin-top: -10px;
  // }
  // .radio {
  //   float: left;
  //   margin-top: 10px !important;
  //   margin-bottom: 10px !important;
  //   font-size: 1.8rem;
  // }
  // .radio + .radio {
  //   margin-left: 25px;
  // }
  // .signin-message {
  //   margin-bottom: 20px;
  // }
  // input[type="radio"] {
  //   margin-top: 3px;
  // }
  // select {
  //   padding-top: 12px !important;
  // }
  // h1 {
  //   margin-top: 0;
  // }
  // h6, .h6 {
  //   color: @gray-darker;
  //   margin-bottom: 20px;
  // }
}

.card-middle {
  padding: 24px;
}

.card--full-width {
  min-height: 0;
  width: 100%;
  padding: 0px;
}

.card--overflow {
  overflow: visible;
  z-index: 100;
}

.upload-card {
  word-wrap: break-word;
  height: 250px;
  width: 183px;
  margin: 8px;
}

.upload-card__subhead {
  font-size: 1.4rem;
  font-weight: 500;
  display: block;
  margin: 0;
  letter-spacing: .01em;
  color: rgba(0, 0, 0, .54);
  line-height: 1.6em;
}

.upload-placeholder {
  margin: 8px;
  padding: 16px;
  height: 250px;
  width: 183px;
  color: #6e6e6e;
  background-color: $gray-border;
  border: 1px solid $gray-border;
  text-align: center;
  cursor: pointer;
}

.upload-placeholder.active {
  background-color: $blue-darker;
  border: $blue-darker;
  color: $offset;
}

// Subscription card
//------------------------

.subscription-card {
  margin-left: 16px;
  margin-bottom: 16px;
}

.subscription-card__title {
  margin: 0px;
}
