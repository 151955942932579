// Upload Section
//----------------------------------------

.zwi-upload-list {
  max-height: 375px;
  overflow-y: auto;
  border: 1px solid $gray-border;
  border-bottom-left-radius: $radius-small;
  border-bottom-right-radius: $radius-small;
}

.zwi-upload-list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
    text-overflow: ellipsis;
  }
  height: 50px;
  background-color: $offset;
  padding: 0 16px;
  border-bottom: 1px solid $gray-border;
  &:hover {
    background-color: $gray-lighter;
  }
}

.zwi-upload-list-item-selected {
  background-color: $gray-lighter !important;
}

//.zwi-upload-section {
//  position: fixed;
//  border-bottom: none;
//  margin-right: 20px;
//  right: 0;
//  bottom: 82px;
//  width: 350px;
//  z-index: 2000;
//}

.zwi-upload-section__header {
  background-color: $dark-background;
  color: $offset;
  font-weight: $bold;
  height: 60px;
  padding: 0 16px;
  border: 1px solid $dark;

  i {
    cursor: pointer;
    color: $dark-offset;
    font-size: 30px;
  }
}
