@mixin margin-selectors($before:1em, $after:1em, $start:0px, $end:0px) {
  -webkit-margin-before: $before;
  -webkit-margin-after: $after;
  -webkit-margin-start: $start;
  -webkit-margin-end: $end;
}

@mixin not-selectable($value:none) {
  -webkit-touch-callout: $value;
  -webkit-user-select: $value;
  -khtml-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin input-placeholder-color($color) {
  &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
      color: $color;
    }
}

@mixin pie-clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin md-shadow-bottom-z-1() {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

@mixin md-shadow-bottom-z-2() {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

// Mixin for a "flat" input that can be used for components that contain an input
// (datepicker, autocomplete).
@mixin md-flat-input() {
  font-size: 14px;

  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;

  // The the "clear X" that IE adds to input[type="search"]
  &::-ms-clear {
    display: none;
  }
}

// Typography mixins

@mixin md-title() {
  font-size: $title-font-size-base;
  font-weight: 500;
  letter-spacing: 0.005em;
}

@mixin md-body-1() {
  font-size: $body-font-size-base;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: rem(2);
}

@mixin md-body-2() {
  font-size: $body-font-size-base;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: rem(2.4);
}

@mixin md-subhead() {
  font-size: $subhead-font-size-base;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: rem(2.4);
}

@function map-to-string($map) {
  $map-str: '{';
  $keys: map-keys($map);
  $len: length($keys);
  @for $i from 1 through $len {
    $key: nth($keys, $i);
    $value: map-get($map, $key);
    $map-str: $map-str + '_' + $key + '_: _' + map-get($map, $key) + '_';
    @if $i != $len {
      $map-str: $map-str + ',';
    }
  }
  @return $map-str + '}';
}


// mixin definition ; sets LTR and RTL within the same style call
// @see https://css-tricks.com/almanac/properties/d/direction/

@mixin rtl($prop, $value, $rtl-value) {
  #{$prop}: $value;

  html[dir=rtl] & {
    #{$prop}: $rtl-value;
    unicode-bidi: embed;
  }
  body[dir=rtl] & {
    #{$prop}: $rtl-value;
    unicode-bidi: embed;
  }

  bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override;
  }
  bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override;
  }


}

// Position a FAB button.
@mixin fab-position($spot, $top: auto, $right: auto, $bottom: auto, $left: auto) {
  &.md-fab-#{$spot} {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: absolute;
  }
}

@mixin fab-all-positions() {
  @include fab-position(bottom-right, auto, ($button-fab-width - $button-fab-padding)/2, ($button-fab-height - $button-fab-padding)/2, auto);
  @include fab-position(bottom-left, auto, auto, ($button-fab-height - $button-fab-padding)/2, ($button-fab-width - $button-fab-padding)/2);
  @include fab-position(top-right, ($button-fab-height - $button-fab-padding)/2, ($button-fab-width - $button-fab-padding)/2, auto, auto);
  @include fab-position(top-left, ($button-fab-height - $button-fab-padding)/2, auto, auto, ($button-fab-width - $button-fab-padding)/2);
}
