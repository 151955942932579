// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// New
@import 'styles/layout/layout';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



// Plus imports for other components in your app

@import 'styles/material/override';
@import 'styles/material/variables';
@import 'styles/material/mixins';

@import 'styles/material/layout';
@import 'styles/material/typography';
@import 'styles/material-design-lite/_list';

@import 'styles/mixins';
@import 'styles/variables';

@import 'styles/objects/objects';
@import 'styles/components/components';

@import 'styles/buttons';
@import 'styles/cards';
@import 'styles/forms';
@import 'styles/header';
@import 'styles/layout';
@import 'styles/lists';
@import 'styles/material';
@import 'styles/menu';
@import 'styles/mobile';
@import 'styles/progress';
@import 'styles/placeholder';
@import 'styles/player';
@import 'styles/search';
@import 'styles/sermon';
@import 'styles/stream';
@import 'styles/studio';
@import 'styles/theming';
@import 'styles/typography';
@import 'styles/upload';
@import 'styles/upload-sermon';

@import "styles/cube/block";
@import "styles/cube/composition";
@import "styles/cube/exception";
@import "styles/cube/utility";

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
