@use './variables';

.zwi-page {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.zwi-main__side {
  flex: none;
  width: variables.$content-side-width;
}

.zwi-main__content {
  flex: 1;
  //padding: 0px 24px 24px 24px;
  min-height: 100%;
  width: 0;
}


.zwi-footer {
  //bottom: 0;
  flex: none;
  //left: 0;
  //position: fixed;
  //right: 0;
  z-index: 10;
}

.zwi-content__wrapper {
  min-height: 100%;
  margin: variables.$content-main-margin;
  max-width: variables.$content-main-max-width;
  padding-top: variables.$content-main-padding-top;
}
