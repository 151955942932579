// Header
//------------------------

body {
    overflow: hidden;
}

//.header-panel {
//    display: flex;
//    height: calc(100% - 75px)!important;
//    flex-direction: column;
//}

.header-panel--full {
    display: flex;
    height: 100%!important;
    flex-direction: column;
}

.main-panel {
    display: flex;
    flex: 1;
    overflow: auto!important;
}

.main-panel.main-panel--light {
    background-color: #fff;
}

.main-container {
    flex: 1;
    flex-basis: 0.00000000001px;
}

.base-background {
    background-color: $gray;
    height: 100%;
    min-height: 100%;
}

.mdl-layout__content {
    overflow: visible !important;
}

.main {
    margin: 0 10px;
}

.main-body-content {
    position: relative;
    overflow: auto;
    height: 100%;
    display: flex;
    flex: 1;
}

.main-content {
    margin: 20px 0;
}

.studio__main-content {
    margin: 0 0 20px;
}

.md-padding {
    padding: 16px;
}

.pull-right {
	float: right !important;
}

.pull-left {
	float: left !important;
}

.divider {
    height: 1px;
    width: 100%;
    background: $gray-border;
}

// Utilities

.zwi-margin-top-small {
    margin-top: 0.5rem;
}

.zwi-margin-top-medium {
    margin-top: 1rem;
}

.zwi-margin-left-small {
    margin-left: 0.5rem;
}

.zwi-full-width {
    width: 100%;
}
