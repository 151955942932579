$gray-darker: lighten(#000, 13.5%);
$gray-lighter: lighten(#000, 94.5%); // #f1f1f1 (navbar at top, default gray buttons);

$offset: #fff;
$dark-offset: #aaa;
$dark: #000;
$gray-light: #dedede;
$gray: #f1f1f1; // (navbar at top, default gray buttons)
$gray-dark: #6e6e6e;
$gray-border: $gray-light;
$divider: $gray-light;
$dark-background: lighten(#000, 5.5%);

$primary: rgb(34, 34, 34);
$accent: #ff200c;
$blue-primary: rgb(33, 150, 243);

$blue-darker: #1B74CC;

$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: rgb(221,44,0);

$input-label: rgba(0,0,0,0.26);

$gray-font: rgba(0,0,0,.54);
$inactive-font: $gray-light;

// Text
$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

// Font size
//------------------------

$base-font-size: 16px;
$menu-font-size: 15px;
$placeholder-font-size: 18px;
$button-font-size: 14px;

// Layout
$form-gutter: 10px;

// Font weight
$bold: 500;
$offset-bold: 700;

// Margin
//------------------------

$medium-margin: 16px;

// Radius


$radius-small: 2px;

// Responsive
//------------------------
$mobile-width: 744px;
