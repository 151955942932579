// Global page styles
//
// [2] Ensure the page always fills at least the entire height of the viewport.
// [3] Prevent iOS text size adjust after orientation change, without disabling user zoom
// [4] Fonts on OSX will look more consistent with other systems that do not
// render text using sub-pixel anti-aliasing.

html, body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;

  min-height: 100%; // [2]

  -webkit-text-size-adjust: 100%; // [3]
  -ms-text-size-adjust: 100%; // [3]

  -webkit-font-smoothing: antialiased; // [4]
  -moz-osx-font-smoothing: grayscale; // [4]
}

md-select, md-card, md-list, md-toolbar,
ul, ol, p, h1, h2, h3, h4, h5, h6 {
  //text-rendering: optimizeLegibility;
}

/************
 * Headings
 ************/
.md-display-4 {
  font-size: $display-4-font-size-base;
  font-weight: 300;
  letter-spacing: -0.010em;
  line-height: $display-4-font-size-base;
}
.md-display-3 {
  font-size: $display-3-font-size-base;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: $display-3-font-size-base;
}
.md-display-2 {
  font-size: $display-2-font-size-base;
  font-weight: 400;
  line-height: rem(6.4);
}
.md-display-1 {
  font-size: $display-1-font-size-base;
  font-weight: 400;
  line-height: rem(4);
}
.md-headline {
  font-size: $headline-font-size-base;
  font-weight: 400;
  line-height: rem(3.2);
}
.md-title {
  @include md-title();
}
.md-subhead {
  @include md-subhead();
}
/************
 * Body Copy
 ************/
.md-body-1 {
  @include md-body-1();
}
.md-body-2 {
  @include md-body-2();
}
.md-caption {
  font-size: $caption-font-size-base;
  letter-spacing: 0.020em;
}
.md-button {
  letter-spacing: 0.010em;
}

/************
 * Defaults
 ************/

button,
select,
html,
textarea,
input {
  font-family: $font-family;
}

select,
button,
textarea,
input {
  font-size: 100%;
}
