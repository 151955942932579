// Material List
//------------------------

.mdl-list {
    display: block;
    padding: 8px 0 ;
    list-style: none;
}

.mdl-list__item {
    display: flex;
    box-sizing: border-box;
    height: 48px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding : 16px 16px 20px 16px;
    cursor: default;
	& .mdl-list__item-primary-content {
		order:0;
    	flex-grow: 2;
    	text-decoration: none;
		& .material-icons {
			font-size: 40px;
      		line-height: 0;
      		vertical-align: middle;
      		padding: 0 12px 0 0;
		}
	}
	& .mdl-list__item-secondary-content {
		display: flex;
    	flex-flow: column;
    	align-items: flex-end;
		& .mdl-list__item-secondary-action label { display: inline; }
		& .mdl-list__item-secondary-info {
      		margin-bottom: 10px;
      		color: rgba(0, 0, 0, 0.54);
		}
		& .mdl-list__item-sub-header {
			padding: 0 0 0 16px;
		}
	}
}