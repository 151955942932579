// Lists
//------------------------

.grid-list-item {
    min-height: 60px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px;
    margin-bottom: -1px;
    list-style: none;
}

.grid-list-item:first-child {
    border-top: none;
}

.grid-list-item:last-child {
    border-bottom: none;
}

.grid-list--simple {
    .grid-list-item {
        border-bottom: none;
        p {
            margin: 0;
        }
    }
    .grid-list-item:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.12);   
    }
}

.list-item__secondary {
	font-size: 1.4rem;
    font-weight: 500;
    display: block;
    margin: 0;
    letter-spacing: .01em;
    color: $gray-font;
    line-height: 1.2em;
}

.list-item__subhead {
	line-height: 1.6em;
    font-size: 1.4rem;
    margin-bottom: 0px;
}

.list-item__note {
    line-height: 1.2em;
    margin: 0;
}

.single-item-list {
    margin-bottom: 10px;
    font-size: 16px;
}

.single-item-list:last-child {
    margin-bottom: 0px;
}

.list-item-text {
    p {
        line-height: 1.4em;
        font-size: 1.4rem;
        margin-bottom: 0px;
    }
}

