@use "../settings/theming/palette";

.zwi-wrapper {
  margin: 0 auto;
  max-width: 600px;
}

.z-wrapper {
  display: flex;
  justify-content: center;
}

.zwi-wrapper-content {
  width: 100% !important;
}

.zwi-wrapper-content--sm {
  width: 100% !important;
}

.zwi-text-overflow--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.zwi-nowrap {
  white-space: nowrap;
}

@media (min-width: 744px) {
  .zwi-wrapper-content {
    max-width: 568px;
  }

  .zwi-wrapper-content--sm {
    max-width: 450px;
  }

  .zwi-hide-gt-m {
    display: none;
  }

  .zwi-hide-m {
    display: block !important;
  }
}

.zwi-md-block {
  display: none;
}

@media (min-width: 744px) {
  .zwi-md-block {
    display: block !important;
  }
}

//@media (min-width: 744px) {
//  .zwi-page-bottom {
//    display: flex;
//    justify-content: center;
//    position: fixed;
//  }
//}

  .zwi-bottom {
    display: flex;
    justify-content: center;
    margin-top: -12px;
    width: 100%;
  }

.zwi-upper-right {
  position: absolute;
  right: 40px;
  top: 20px;
}

.zwi-center {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.zwi-visual-center {
  @extend .zwi-center;
  margin-top: -3%;
}

.zwi-page-content {
  padding: 36px;
  position: relative;
}

// Flex
.zwi-flex-center {
  display: flex;
  align-items: center;
}

.zwi-flex-start {
  display: flex;
  align-items: flex-start;
}

.zwi-justify-center {
  display: flex;
  justify-content: center;
}

.zwi-right {
  display: flex;
  justify-content: end;
}

.zwi-flex-1 {
  flex: 1;
}

.zwi-column-center {
  align-items: center;
  display: flex;
  flex-flow: column;
}

.zwi-spacer {
  flex: 1 1 auto;
}

// Display

.zwi-inline-block {
  display: inline-block;
}

.zwi-hide-m {
  display: none;
}

// Margin

.zwi-ml-md {
  margin-left: 8px;
}


// Colors

.zwi-red {
  background: map-get(palette.$colors, "red") !important;
}

.zwi-pink {
  background: map-get(palette.$colors, "pink") !important;
}

.zwi-purple {
  background: map-get(palette.$colors, "purple") !important;
}

.zwi-deep-purple {
  background: map-get(palette.$colors, "deep-purple") !important;
}

.zwi-indigo {
  background: map-get(palette.$colors, "indigo") !important;
}

.zwi-blue {
  background: map-get(palette.$colors, "blue") !important;
}

.zwi-light-blue {
  background: map-get(palette.$colors, "light-blue") !important;
}

.zwi-cyan {
  background: map-get(palette.$colors, "cyan") !important;
}

.zwi-teal {
  background: map-get(palette.$colors, "teal") !important;
}

.zwi-green {
  background: map-get(palette.$colors, "green") !important;
}

.zwi-light-green {
  background: map-get(palette.$colors, "light-green") !important;
}

.zwi-yellow {
  background: map-get(palette.$colors, "yellow") !important;
}

.zwi-amber {
  background: map-get(palette.$colors, "amber") !important;
}

.zwi-orange {
  background: map-get(palette.$colors, "orange") !important;
}

.zwi-deep-orange {
  background: map-get(palette.$colors, "deep-orange") !important;
}

// Pinch zoom
.zwi-scroll-y,
.zwi-scroll-x {
  -webkit-overflow-scrolling: touch;

  /**
   * Note from: https://github.com/ionic-team/ionic-framework/blob/b5c869d015688a975891349aac41adada5c0609d/core/src/components/content/content.scss
   * This adds `.inner-scroll` as part of the
   * stacking context in WebKit. Without it,
   * children of ion-content are treated as
   * siblings rather than descendants. This
   * can result in the children being put
   * into their own layers, degrading
   * scrolling performance.
   *
   * An optimization called "layer backing
   * sharing" usually kicks in to prevent
   * this, but having translate3d defeats
   * this optimization.
   *
   * See: https://bugs.webkit.org/show_bug.cgi?id=216701
   */
  z-index: 0;

  will-change: scroll-position;
}

.zwi-scroll-y {
  touch-action: pan-y;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.zwi-scroll-x {
  touch-action: pan-x;
  overflow-x: auto;
  overscroll-behavior-x: contain;
}

.zwi-scroll-x.zwi-scroll-y {
  touch-action: auto;
}

.zwi-pinch-zoom {
  touch-action: pinch-zoom;
}

.zwi-pinch-zoom {
  touch-action: pinch-zoom;
}

.zwi-pinch-zoom.zwi-scroll-y {
  touch-action: pan-y pinch-zoom;
}

.zwi-pinch-zoom.zwi-scroll-x {
  touch-action: pan-x pinch-zoom;
}

.zwi-pinch-zoom.zwi-scroll-y.zwi-scroll-x {
  touch-action: auto pinch-zoom;
}


// Text
.zwi-text-center {
  text-align: center;
}

.zwi-bold {
  font-weight: 500 !important;
}
