@use '../../variables';
@use '@angular/material' as mat;


.zwi-search-bar {
  @include mat.elevation(2);
  align-items: center;
  display: flex;
  padding: 0px;
}

.zwi-search-bar__form {
  width: 100%;
}

.zwi-search-bar__input {
  border: none;
  font-size: 16px;
  line-height: 3.2 !important;
  margin: 0;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.zwi-search__button {
  color: $gray-font;
  flex: none;
  margin-right: 16px;
  margin-left: 16px;
  cursor: default;
}

.zwi-search__button--active {
  color: variables.$accent;
  cursor: pointer;
}

.search__headline {
  font-size: $placeholder-font-size;
  color: $gray-dark;
  margin-top: $medium-margin;
}

//.searchbar {
//  form {
//    width: 100%;
//    margin-bottom: 0px;
//  }
//  input {
//    line-height: 3.2 !important;
//    margin: 0;
//    border: none;
//  }
//  input:focus {
//    outline: none;
//  }
//}
