// Header
//------------------------

.mdl-layout__header-row {
  padding: 0 !important;
}

.active-header {
  color: $offset;
}

.header-row {
  min-height: 100px;
  margin-bottom: 32px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e3e3e3;
}
