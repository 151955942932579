.zwi-section > * + * {
  margin-top: 20px !important;
}

.zwi-card-section > * + * {
  margin-top: 16px !important;
}

.zwi-block-section > * + *  {
  margin-top: 8px;
}

.zwi-block-section--horizontal > * + * {
  margin-left: 8px;
}

.zwi-section--horizontal > * + * {
  margin-left: 16px;
}

.zwi-text-section > * + * {
  margin-top: 16px;
}

.zwi-buttons > * + * {
  margin-left: 8px !important;
}

.zwi-page-sections > * + * {
  margin-top: 32px !important;
}

.zwi-content__main {
  box-sizing: border-box;
  flex: 1;
  padding-right: 16px;
  padding-left: 16px;
}

@media (min-width: 744px) {
  .zwi-content__main {
    padding: 0px !important;
  }
}
