.zwi-vertical-form {
 width: 600px;
}

.zwi-vertical-form__full-field {
  width: 100%;
}

.zwi-vertical-form__md-field {
  width: 70%;
}

