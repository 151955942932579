.mobile-header {
    position: relative;
    z-index: 200;
}

@media (max-width:600px) {
    .md-display-2 {
        font-size: 24px;
        line-height: 28px;
    }

    .header-panel {
        height: 100%!important;
    }

    .header-panel.header-panel--playing {
        height: calc(100% - 120px)!important;
    }

    .player-container {
        height: 120px!important;
        min-height: 120px!important;
    }

    .player__middle {
        height: 70px!important;
    }
}

@media (max-width:959px) {
    header.mdl-layout__header {
        display: none !important;
    }
}

@media (min-width:960px) {
    .play-container {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }

    .mdl-layout__header-row {
        padding: 0 30px 0 20px !important;
    }
}

@media screen and (max-width: 1024px) {
    .mdl-layout__header {
        display: flex !important;
    }
}
