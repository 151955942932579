// Upload Sermon
//----------------------------------------

.grid-placeholder {
    background-color: $gray-light;
    border-radius: $radius-small;
}

.upload-sermon__handle {
    height: 100%;
    background-color: $blue-darker;
    color: $offset;
    font-weight: $bold;
    font-size: 24px;
    text-align: center;
    padding: 10px;
}

.upload-sermon__card {
    min-height: 70px;
}

.upload-sermon__link {
    cursor: pointer;
}

