@use '@angular/material' as mat;

@import '../../tools/icon';

.zwi-tile {
  display: flex;
  flex-flow: column;
  height: 202px;
  padding: 24px;
  word-wrap: break-word;
}

.zwi-tile-card {
  @include mat.elevation(2);
  @extend .zwi-tile;
}

.zwi-tile__content {
  flex: 1;
  padding-top: 24px;
}

.zwi-tile__actions {
  display: flex;
  justify-content: flex-end;
}

.zwi-tile-button {
  @extend .zwi-tile;
  align-items: center;
  background-color: $gray-border;
  color: #6e6e6e;
  cursor: pointer;
  justify-content: center;
  text-align: center;

  &:hover {
    background-color: $blue-darker;
    color: $offset;
  }
}

.zwi-tile__icon {
  @include zwi-icon-size(56px);
}

//.uploadFile-card__subhead {
//  font-size: 1.4rem;
//  font-weight: 500;
//  display: block;
//  margin: 0;
//  letter-spacing: .01em;
//  color: rgba(0, 0, 0, .54);
//  line-height: 1.6em;
//}

//.uploadFile-placeholder {
//  margin: 8px;
//  padding: 16px;
//  height: 250px;
//  width: 183px;
//  color: #6e6e6e;
//  background-color: $gray-border;
//  border: 1px solid $gray-border;
//  text-align: center;
//  cursor: pointer;
//}

//.uploadFile-placeholder.active {
//  background-color: $blue-darker;
//  border: $blue-darker;
//  color: $offset;
//}
