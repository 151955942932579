// Daily sermons component

.ministry__info {
    margin-top: 16px;
    margin-bottom: 8px;
}

.ministry__profile-image {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-left: 8px;
}

.ministry__name {
    margin: 0 0 0 8px;
}