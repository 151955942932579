@import "variables";

.mat-success {
  background: $brand-success !important;
  color: white;
}

button,
a {
  &.mat-mdc-button,
  &.mat-mdc-raised-button,
  &.mat-mdc-unelevated-button,
  &.mat-mdc-outlined-button {
    .mat-icon {
      vertical-align: top;
      font-size: 1.25em;
    }
  }
}

// Button
//------------------------

.button-paddle {
	width: 100%;
	height: 40px;
}

.button--select {
    font-weight: normal;
}

.button--select-active {
    font-weight: $bold;
}

.close-button {
    position: absolute;
    top: 0;
    right: -8;
    font-size: 14px;
    color: $gray-font;
    cursor: default;
    &:hover {
        color: $dark;
    }
}

.mdl-button--offset {
	color: $offset;
}

.mdl-button--raised.mdl-button--offset {
	color: $dark;
	background-color: $offset;
}

.local-church-tag {
    font-size: 14px;
    font-weight: $bold;
}

.subscribe-button {
    color: $brand-success !important;
    &:hover {
        background-color: rgba(92, 184, 92, 0.12) !important;
    }
}

.subscribed-button {
    background-color: $brand-success !important;
    color: $offset !important;
    &:hover {
        background-color: $brand-success !important;
    }
}

// Hyperlink

.link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
}

// Tag
//------------------------

.tag {
    padding: 2px 6px 4px 2px;
    border-radius: 2px;
    color: $gray-dark;
    span {
        vertical-align: middle;
    }
}

.tag__icon {
    font-size: 14px;
    margin-right: 2px;
}

.tag--success {
    background-color: $brand-success;
    color: $offset !important;
}

.tag--info {
    padding: 2px 4px;
    color: $offset;
    line-height: 1;
    font-size: 12px;
    font-weight: $offset-bold;
    background-color: $dark-background;
    &:first {
        margin-left: 12px;
    }
}
