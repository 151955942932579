// Menu
//------------------------
@use "./variables";

.material-icons {
	vertical-align: middle;
}

.menu--main {
	width: 320px;
}

.mdl-menu__item {
	font-size: 15px;
}

.menu--caption {
	margin-left: 16px;
}

.menu-item--active {
    background: $gray;
    color: $blue-primary;
    font-weight: 500;
}

.menu-item--simple-active {
  & .mdc-list-item__start,  {
    color: variables.$primary !important;
  }

  & .mdc-list-item__primary-text {
    color: variables.$primary !important;
  }
}

.overflow {
    overflow-y: scroll;
}

// Book Menu
//------------------------

.book-menu {
    width: 610px;
    font-size: $menu-font-size;
}

.book-menu__subhead {
    color: $gray-font;
}

.book-menu-item {
    font-weight: normal;
    cursor: pointer;
    &:hover {
        font-weight: $bold;
    }
}

// Menu Row
//------------------------

.menu-row {
	min-height: 20px;
    padding-top: 19px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
}

.menu-row--title {
	margin: 10px 20px 10px 10px;
    color: #222;
}

.menu-row__title--fixed-width {
    width: 229px;
}

// Select menu
//------------------------

.mdl-button--selected {
    text-transform: none;
    font-weight: normal;
    font-size: $menu-font-size;
}

.select-menu__caption {
    white-space: nowrap;
}

.select-menu-container {
    z-index: 99;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
}

.select-menu-container--visible {
    visibility: visible;
}

.select-menu {
    margin: 0;
    width: auto;
    max-height: 256px;
    min-height: 48px;
    min-width: 136px;
    font-size: $menu-font-size;
    // height: 36px;
    // border-radius: 2px;
    // cursor: pointer;
    // text-transform: uppercase;
    // font-size: $button-font-size;
    // font-weight: $bold;
}

.select-menu__content {
    display: block;
    max-height: 256px;
    min-height: 48px;
    min-width: 136px;
}

.select-menu__item {
    height: 48px;
    line-height: 48px;
    display: block;
    border: none;
    color: rgba(0,0,0,0.87);
    padding: 0 16px;
    outline-color: #bdbdbd;
    overflow: hidden;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
        background: $gray;
    }
}

.select-menu--selected {
    font-weight: normal;
    font-size: $base-font-size;
    text-transform: none;
}

// Side Menu
//------------------------

.side-menu {
    margin-left: 10px;
}

.side-menu__menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.side-menu__menu-item {
    display: block;
    padding: 15px 20px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        background: $gray;
    }
}

.side-menu__title {
    padding: 20px 20px 15px 20px;
}

.side-menu__icon {
    margin-right: 16px;
}

// Tabs
//------------------------

.mdl-tabs__tab {
    cursor: pointer;
}

