.zwi-sidenav {
  column-gap: 24px;
  display: grid;
  grid-template: "side main" / 300px 1fr;
  margin: 16px;
}

.zwi-sidenav__side {
  grid-area: side;
}

.zwi-sidenav__main {
  grid-area: main;
}
