@use '@angular/material' as mat;

@use 'variables';

$typography: mat.m2-define-typography-config($body-1: mat.m2-define-typography-level(16px, 1.125, 400));

.zwi-title {
  @include mat.m2-typography-level($typography, 'headline-5');
}

.zwi-subtitle {
  @include mat.m2-typography-level($typography, 'subtitle-1')
}

// Previous
//-----------------------

button,
select,
html,
body,
textarea,
input {
	font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.category__title {
	text-transform: uppercase;
    margin: 4px 0 4px 8px;
}

.headline--blank {
    font-size: variables.$placeholder-font-size;
    color: variables.$gray-dark;
    margin-top: variables.$medium-margin;
}

i.success-state {
	color: variables.$brand-success;
}

.page-header {
	margin-top: 20px;
}

.layout-row-text {
	margin-top: 0;
	margin-bottom: 0;
}

a {
	color: variables.$gray-darker;
}

.bold {
	font-weight: 500;
}

.text-center {
	text-align: center;
}

.title-message {
	margin-bottom: 4px;
}

// Special
//------------------------

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #dedede;
	padding: 0;
	width: 100%;
}

.material-icons--large {
    font-size: 50px;
}

.material-icons--success {
    color: variables.$brand-success;
}

.material-icons--warning {
    color: variables.$brand-warning;
}
