// Sermons
//------------------------

.category-sermons {
    margin-top: 32px;
}

.sermon__extra {
    border-top: 1px solid $gray-border;
}


// Archive editor
//------------------------

.archive-editor {
	padding: 16px;
}

.archive-editor__textfield {
    width: 100%;
}

// Sermon editor
//------------------------

.sermon-editor {
    // background-color: #fafafa;
    box-shadow: inset 0 8px 8px -8px rgba(0,0,0,0.14), inset 0 -8px 8px -8px rgba(0,0,0,0.14);
    overflow: visible;
}
