@use '@angular/material' as mat;
@use 'sass:map';

$zwi-reference-picker-selected-current-box-shadow-width: 1px;
$zwi-reference-picker-selected-fade-amount: 0.6;
$zwi-reference-picker-current-fade-amount: 0.2;
$zwi-reference-picker-body-font-size: 13px !default;

@mixin _zwi-reference-picker-color($palette) {
  .zwi-reference-body-selected {
    background-color: map.get($palette, default);
    color: map.get($palette, default-contrast);
  }

  .zwi-reference-body-disabled > .zwi-reference-body-selected {
    background-color: fade-out(map.get($palette, default), $zwi-reference-picker-selected-fade-amount);
  }

  .zwi-reference-body-current.zwi-reference-body-selected {
    box-shadow: inset 0 0 0 $zwi-reference-picker-selected-current-box-shadow-width
    map.get($palette, default-contrast);
  }
}

@mixin zwi-reference-picker-theme($theme) {
  .zwi-reference-arrow {
    border-top-color: mat.get-theme-color($theme, foreground, icon);
  }

  .zwi-reference-picker-toggle,
  .zwi-reference-picker-content .zwi-reference-next-button,
  .zwi-reference-picker-content .zwi-reference-previous-button {
    color: mat.get-theme-color($theme, foreground, icon);
  }

  .zwi-reference-table-header {
    color: mat.get-theme-color($theme, foreground, hint-text);
  }

  .zwi-reference-table-header-divider::after {
    background: mat.get-theme-color($theme, foreground, divider);
  }

  .zwi-reference-body-label {
    color: mat.get-theme-color($theme, foreground, secondary-text);
  }

  .zwi-reference-body-cell-content {
    color: mat.get-theme-color($theme, foreground, text);
    border-color: transparent;
  }

  .zwi-reference-body-disabled > .zwi-reference-body-cell-content:not(.zwi-reference-body-selected) {
    color: mat.get-theme-color($theme, foreground, disabled-text);
  }

  .zwi-reference-body-cell:not(.zwi-reference-body-disabled):hover,
  .cdk-keyboard-focused .zwi-reference-body-active,
  .cdk-program-focused .zwi-reference-body-active {
    & > .zwi-reference-body-cell-content:not(.zwi-reference-body-selected) {
      background-color: mat.get-theme-color($theme, background, hover);
    }
  }

  .zwi-reference-body-range {
    background-color: #e6f3ff;
  }

  .zwi-reference-body-range.zwi-reference-body-cell-selected-start {
    height: 100%;
    background: linear-gradient(90deg, #fff 50%, #e6f3ff 50%);
  }

  .zwi-reference-body-range.zwi-reference-body-cell-selected-end {
    height: 100%;
    background: linear-gradient(90deg, #e6f3ff 50%, #fff 50%);
  }

  .zwi-reference-body-range.zwi-reference-body-cell-selected-solo {
    height: 100%;
    background: #fff;
  }

  .zwi-reference-body-current:not(.zwi-reference-body-selected) {
    border-color: mat.get-theme-color($theme, foreground, hint-text);
  }

  .zwi-reference-body-disabled > .zwi-reference-body-current:not(.zwi-reference-body-selected) {
    border-color: fade-out(mat.get-theme-color($theme, foreground, hint-text), $zwi-reference-picker-current-fade-amount);
  }

  $color-config: mat.m2-get-color-config($theme);
  @include _zwi-reference-picker-color(map.get($color-config, primary));

  .zwi-reference-picker-content {
    background-color: mat.get-theme-color($theme, background, card);
    color: mat.get-theme-color($theme, foreground, text);

    &.mat-accent {
      @include _zwi-reference-picker-color(map.get($color-config, accent))
    }

    &.mat-warn {
      @include _zwi-reference-picker-color(map.get($color-config, warn))
    }
  }

  .zwi-reference-picker-toggle-active {
    color: mat.get-theme-color($theme, primary);

    &.mat-accent {
      color: mat.get-theme-color($theme, accent);
    }

    &.mat-warn {
      color: mat.get-theme-color($theme, warn);
    }
  }
}


@mixin zwi-reference-picker-typography($config) {
  .zwi-reference {
    font-family: mat-font-family($config);
  }

  .zwi-reference-body {
    font-size: $zwi-reference-picker-body-font-size;
  }

  .zwi-reference-body-label,
  .zwi-reference-period-button {
    font: {
      size: mat.get-theme-typography($theme, button, font-size);
      //size: mat.get-theme-typography($theme, button, font-size) mat-font-family($config, button);
      weight: mat.get-theme-typography($theme, button, font-weight);
      //weight: mat-font-weight($config, button);
    }
  }

  .zwi-reference-table-header th {
    font: {
      size: $mat-calendar-weekday-table-font-size;
      weight: mat.get-theme-typography($theme, body-1, font-weight);
      //weight: mat-font-weight($config, body-1);
    }
  }
}
